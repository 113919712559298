/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { Button, Row, Col, Divider, Popconfirm , Tooltip} from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import userAcrions from '../../../Redux/user/actions';
import PricingTag from "../Helpers/PricingTag";
import {FormCheckbox, FormInput, FormRadio ,FormSelect,FormUpload} from "../../../helpers/forms";

import greenSticker from '../../../resources/images/general/vignette_verte.jpg';
import orangeSticker from '../../../resources/images/general/vignette_orange.jpg';
import redSticker from '../../../resources/images/general/vignette_rouge.jpg';
import NumberFormat from "react-number-format";
import _ from "lodash";
import {colors} from "../../../helpers/appColors";
import CustomModal from "./CustomModal.style";
import {hasAccessToSticker, canUseSticker} from "../../../helpers/userHelper";
import moment from 'moment';
import { QuestionCircleOutlined } from '@ant-design/icons';

const radioOptions=[
  {value: 'soi', label: (<span>
    <Tooltip placement='top' title='Disponible à partir de la formule Starter'>
    A expédier soi-même{' '}
    </Tooltip>
  </span>
  )},
  {value: 'ecopli', label:(
    <span>
      Ecopli 1,80€ ht {' '}
      <Tooltip placement='top' title=' On imprime, on expédie pour vous et vous suivez la distribution du courrier, en temps réel, sur votre tableau de bord (Distribué, Retour…)'><QuestionCircleOutlined style={{cursor: 'pointer',color: colors.primaryBlack}} /></Tooltip>
    </span>
  )},
  {value: 'lrar', label:(
    <span>
      Recommandé avec AR 8,60€ ht {' '}
      <Tooltip placement='top' title=' On imprime, on expédie pour vous et vous suivez la distribution du courrier, en temps réel, sur votre tableau de bord (Distribué, Retour…)'><QuestionCircleOutlined style={{cursor: 'pointer',color: colors.primaryBlack}} /></Tooltip>
    </span>
  )}
];


class MiseEnDemeureModal extends Component {

  state = {
    maxLength:45
  };

  componentDidMount(){
    if(this.props.formRef.current){
      // SOME CODE
    }

    const oneWeekAgo = moment().subtract(7, 'days');
    const { user } = this.props;
  
    let defaultOptionValue = 'soi'; // Default to 'A expédier soi-même'
  
    if (user.subscriptionType === 'Free' && oneWeekAgo.isAfter(moment(user.createdAt))) {
      // If the subscription type is 'Free' and the trial week has passed
      defaultOptionValue = 'ecopli'; // Set default to 'Ecopli'
    }
  
    const updatedOptions = radioOptions && radioOptions.map(option => {
      if (option.value === 'soi' && user.subscriptionType === 'Free' && oneWeekAgo.isAfter(moment(user.createdAt))) {
        return { ...option, disabled: true };
      }
      return option;
    });
  
    this.setState({
      selectedRadioValue: updatedOptions.find(option => option.value === defaultOptionValue),
      disabledOptions: updatedOptions,
      // ... (existing code)
    });

    this.setState({
      paperType : user.paperType,
      filePaperVisible: (user.paperType === 'custom')
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(
      (
        this.props.customerMiseEnDemeureVisible !== prevProps.customerMiseEnDemeureVisible
        || this.props.myCompanies !== prevProps.myCompanies
      )
      && this.props.customerMiseEnDemeureVisible
    ) {
      if (this.props.formRef.current) {
        const {
          companyId,
          miseEnDemeureGender,
          miseEnDemeureCompanyName,
          miseEnDemeureAddress,
          miseEnDemeureZip,
          miseEnDemeureCity
        } = this.props.formRef.current.getFieldsValue();
        if(companyId){
          const company = _.find(this.props.myCompanies, {id: parseInt(companyId)});
          if(company){
            for(const key in company){
              if(company[key] === 'null') {
                company[key] = null
              }
            }
            this.props.formRef.current.setFieldsValue({
              miseEnDemeureGender: miseEnDemeureGender ? miseEnDemeureGender : company.gender,
              miseEnDemeureCompanyName: miseEnDemeureCompanyName ? miseEnDemeureCompanyName : company.name,
              miseEnDemeureAddress: miseEnDemeureAddress ? miseEnDemeureAddress : company.address,
              miseEnDemeureZip: miseEnDemeureZip ? miseEnDemeureZip : company.zip,
              miseEnDemeureCity: miseEnDemeureCity ? miseEnDemeureCity : company.city
            })
          }
        }
      }
    }
  }

  onFinish = () => {
    if (this.props.formRef && this.props.formRef.current) {
      // Mettez à jour la valeur par défaut de reminderDelegatedSending dans le formulaire
      this.props.formRef.current.setFieldsValue({
        miseEnDemeureDelegatedSending: this.state.selectedRadioValue.value,
      });
    }
    this.props.formRef.current.setFieldsValue({submitType: 'mise_en_demeure'});
    this.props.formRef.current.submit();
  };

  handleOnChange = (value) => {
    this.setState({
      filePaperVisible : value === 'custom',
      paperType : value
    });
  }

  updateOptions = () => {
    const dataToSent = {
      filePaper: this.state.filePaper ? this.state.filePaper : null,
      paperType : this.state.paperType
    }
    
    this.props.requestUpdateOptions(dataToSent);
  }

  handleFilePaper = file => {
    this.setState({ filePaper: file });
    return false;
  };

  handleOnClickRadioGender = (e) => {
    const values = this.props.formRef.current.getFieldsValue();
    const {miseEnDemeureGender} = values;
    if(miseEnDemeureGender === e.target.value){
      this.props.formRef.current.setFieldsValue({
        miseEnDemeureGender: null
      })
    }
  }

  handleOnClickRadioDelegatedSending = (e) => {
   
    const selectedValue = e.target.value;  
    // Mise à jour de l'état pour la valeur sélectionnée
    this.setState({
      selectedRadioValue: radioOptions.find((option) => option.value === selectedValue),
    });
  
    const values = this.props.formRef.current.getFieldsValue();
    const { miseEnDemeureDelegatedSending } = values;
     // if(miseEnDemeureDelegatedSending === e.target.value){
    //   this.props.formRef.current.setFieldsValue({
    //     miseEnDemeureDelegatedSending: null
    //   })
    // }
  }

  handleOnclickRadioStickers = (e) => {
    const values = this.props.formRef.current.getFieldsValue();
    const {miseEnDemeureSelectedSticker} = values;
    if(miseEnDemeureSelectedSticker === e.target.value){
      this.props.formRef.current.setFieldsValue({
        miseEnDemeureSelectedSticker: null
      })
    }
  }

  handleAdresseChange = (e) => {
    const inputValue = e.target.value;
    const lines = inputValue.split("\n").map((line) => line.slice(0, 45));    
    const truncatedLines = lines.slice(0, 3);
    const truncatedValue = truncatedLines.join("\n");
    this.props.formRef.current.setFieldsValue({
      miseEnDemeureAddress: truncatedValue,
    });
  }

  render(){

    const { user,appDocuments } = this.props;

    const chartDocument = _.find(appDocuments, {name: 'charte_bbp'});

    const style = {
      modal: {
       textAlign: 'left',
      },
    };
    
    const disabled=!(user.paperType === 'custom');

    
    const defaultRadioOption='ecopli';

    const paperOptions = [
      {text: 'Aucun en-tête', value: 'none'},
      {text: 'Mon en-tête', value: 'custom'}
    ];


    if(!user) return <></>;

    return (
      <CustomModal
        title={`Mise en demeure`}
        visible={this.props.customerMiseEnDemeureVisible}
        onCancel={() => this.props.hideModal('customerMiseEnDemeure')}
        footer={null}
        style={style.modal}
        destroyOnClose={true}
      >
        <Row gutter={16} style={{textAlign: 'center', margin: 0, background: colors.primaryGreen}}>
          <Col span={6}><PricingTag title={'FREE'} subtitle={'Gratuit*'} active={'Free' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'STARTER'} subtitle={'Gratuit'} active={'Starter' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PREMIUM'} subtitle={'Gratuit'} active={'Premium' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PRIVILÈGE'} subtitle={'Gratuit'} active={'Privilege' === user.subscriptionType}/></Col>
          <Col span={24} style={{marginBottom: 10, color: "white"}}>
            <em>* Gratuit la première semaine</em>
          </Col>
        </Row>

        <br/>

        <p style={{textAlign: 'center'}}>
        <em>
          Saisissez les coordonnées du destinataire
        </em>
        </p>
        <Row gutter={16} style={{margin: 0}}>
          <Col span={4} offset={4} style={{textAlign: 'center', paddingTop: 20}}>
            <FormRadio
              onClick={this.handleOnClickRadioGender}
              name={'miseEnDemeureGender'}
              label={null}
              initialValue={'none'}
              id={'mister'}
              options={[{value: 'Mme', label: 'Mme'},{value: 'M.', label: 'M.'}]}
              layout={'horizontal'}
            />
          </Col>
          <Col span={11}>
            <FormInput
              label={`Raison sociale du destinataire`}
              alignLabel='center'
              placeholder={`Raison sociale`}
              name={`miseEnDemeureCompanyName`}
              type='text'
              rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false}]}
              disabled={true}
            />
          </Col>
          <Col span={15} offset={4}>
            <FormInput
              label={`Adresse`}
              alignLabel='center'
              placeholder={`Adresse (Maximum 3 lignes, 45 caractères par ligne)`}
              name={`miseEnDemeureAddress`}
              maxLength={135}
              type='textArea'
              rules={[{required: true, message: 'Ce champ est obligatoire'},{max : 134 ,  message:`Maximum 135 caractères`, warningOnly: true}]}
              onChange={this.handleAdresseChange}
            />
          </Col>
          <Col span={6} offset={4}>
            <FormInput
              label={`Code postal`}
              alignLabel='center'
              placeholder={`Code postal`}
              name={`miseEnDemeureZip`}
              type='text'
              rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false}]}
            />
          </Col>
          <Col span={9}>
            <FormInput
              label={`Ville`}
              alignLabel='center'
              placeholder={`Ville`}
              name={`miseEnDemeureCity`}
              type='text'
              maxLength={39}
              rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false},{max:38,message:'Maximum 39 caractères'}]}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{marginTop: 20, marginLeft: 0, marginRight:0}}>
          <Col span={24} style={{backgroundColor: '#f5f5f5', borderRadius: 5, padding: 20, textAlign: 'center'}}>
          <strong style={{fontStyle: 'italic', textDecoration: 'underline'}}>Ajoutez un sticker BBP</strong> :<br/>
            <br/>
            <div  style={{display:"none"}}>
              <FormInput
                type='hidden'
                name={`miseEnDemeureHasStickers`}
                initialValue={hasAccessToSticker(user)}
              >
              </FormInput>
            </div>
            {user.chartAcceptedAt ? '' : (
                <>
                  <Col span={24} style={{textAlign:"center",marginTop:10,color:colors.primaryRed}}>
                    Pour utiliser nos stickers de couleur, vous devez accepter notre charte
                  </Col>
                  <Col span={24} style={{textAlign:"center",marginTop:10}}>
                      <Button type='ghost' target="_blank" rel="noreferrer" style={{marginRight:20}} href={chartDocument.url} >Télécharger</Button>

                      <Popconfirm
                        placement="top"
                        title={`Voulez-vous vraiment accepter la charte ?`}
                        onConfirm={()=>this.props.requestUpdateUser({chartAcceptedAt: moment().format('YYYY-MM-DD')})}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <Button type={'primary'}  style={{marginLeft:20}}>Accepter</Button>
                      </Popconfirm>
                  </Col>
                </>
            )}
            <br/>
            <Col span={8} style={{textAlign:'center', display: 'inline-block'}}>
              <label htmlFor={`miseEnDemeureGreen`}><img src={greenSticker} style={{width: '100%', border: 'solid lightgrey 1px', padding: 5, borderRadius: 5}}/></label>
              <FormRadio
                onClick={this.handleOnclickRadioStickers}
                name={`miseEnDemeureSelectedSticker`}
                options={[{value: 'green'}]}
                id='miseEnDemeureGreen'
                disabled={!(user.chartAcceptedAt && canUseSticker(user, 'green'))}
              />
            </Col>
            <Col span={8} style={{textAlign:'center', display: 'inline-block'}}>
              <label htmlFor={`miseEnDemeureOrange`}><img src={orangeSticker} style={{width: '100%', border: 'solid lightgrey 1px', padding: 5, borderRadius: 5}}/></label>
              <FormRadio
                onClick={this.handleOnclickRadioStickers}
                name={`miseEnDemeureSelectedSticker`}
                options={[{value: 'orange'}]}
                id='miseEnDemeureOrange'
                disabled={!(user.chartAcceptedAt && canUseSticker(user, 'orange'))}
              />
            </Col>
            <Col span={8} style={{textAlign:'center', display: 'inline-block'}}>
              <label htmlFor={`miseEnDemeureRed`}><img src={redSticker} style={{width: '100%', border: 'solid lightgrey 1px', padding: 5, borderRadius: 5}}/></label>
              <FormRadio
                onClick={this.handleOnclickRadioStickers}
                name={`miseEnDemeureSelectedSticker`}
                options={[{value: 'red'}]}
                id='miseEnDemeureRed'
                disabled={!(user.chartAcceptedAt && canUseSticker(user, 'red'))}
              />
            </Col>
            <br/>
            <br/>
            <strong style={{fontStyle: 'italic', textDecoration: 'underline'}}>Comment souhaitez-vous envoyer ce courrier ?</strong> :
            <br/>
            {/*
                (user.paperType === 'custom') ? (
                  <>
                    <br/>
                    <br/>
                    <span style={{color: colors.primaryRed}}>Pour utiliser les options <span style={{textDecoration:'underline'}}>Ecopli</span> et <span style={{textDecoration:'underline'}}>Recommandé</span> avec AR, vous devez télécharger votre papier en-tête</span>
                  </>   
                ): ''
                */}
            <br/>
            <br/>
            <Col span={24} style={{display:'flex',alignItems:'center',paddingLeft:62}}>
                <FormSelect onChange={this.handleOnChange} name='paperType' options={paperOptions} initialValue={user.paperType ? user.paperType : 'none'}/>
                <Button
                  type='default'
                  style={{marginLeft: 25,marginBottom:6}}
                  onClick={this.updateOptions}
                  size='small' className='blue-btn'>
                  Enregistrer
                </Button>
            </Col>
            {
              this.state.filePaperVisible && (
                <>
                  <Col span={20} style={{margin: '0px auto'}}>
                    <Row gutter={16}>
                        <Col span={11}>
                            <FormUpload
                              buttonLabel='Papier en-tête (format .jpg)'
                              name={`paper`}
                              valuePropName={`file`}
                              accept={`.jpg`}
                              action={'#'}
                              multiple={false}
                              beforeUpload={this.handleFilePaper}
                            />
                        </Col>
                        <Col span={13}>
                          <img src={user.paperPath} alt='' style={{width: '100%'}} />
                        </Col>
                    </Row>
                  </Col>
                </>
              )
            }
            <p style={{textAlign: 'center'}}>
              <FormRadio
                onClick={this.handleOnClickRadioDelegatedSending}
                name={'miseEnDemeureDelegatedSending'}
                label={'M.'}
                id={'mister'}
                options={this.state.disabledOptions}
                layout={'horizontal'}
                defaultValue={this.state.selectedRadioValue ? this.state.selectedRadioValue.value : undefined}
                //disabled={this.state.selectedRadioValue && this.state.selectedRadioValue.value === 'soi' && this.props.user.subscriptionType === 'Free'}
              />
            </p>
          </Col>
        </Row>
        <p style={{textAlign: 'center', marginTop: 20}}>
          <em>Montant en principal:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalBalance}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong><br/>
          <em>Pénalités de retard calculées:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalPenalties}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong>
        </p>

        <div style={{textAlign: 'center'}}>
          <Button type='primary' onClick={this.onFinish}>Visualiser</Button>
        </div>
      </CustomModal>
    )
  }
}

export default connect(
  state => ({
    customerMiseEnDemeureVisible: state.modals.customerMiseEnDemeureVisible,
    user: state.user.info,
    globalPenalties: state.calculator.globalPenalties,
    globalBalance: state.calculator.globalBalance,
    myCompanies: state.calculator.myCompanies,
    appDocuments: state.app.documents
  }),
  {
    hideModal: modalsActions.hideModal,
    requestUpdateUser: userAcrions.requestUpdateUser,
    requestUpdateOptions : userAcrions.reqUpdateOptions
  }
)(MiseEnDemeureModal);