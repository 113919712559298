import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import NumberFormat from "react-number-format";
import { Button, Row, Col, Divider } from 'antd';
import modalsActions from '../../../Redux/modals/actions';
import PricingTag from "../Helpers/PricingTag";
import {FormInput, FormRadio} from "../../../helpers/forms";
import _ from "lodash";
import {colors} from "../../../helpers/appColors";
import CustomModal from "./CustomModal.style";


class MiseEnDemeureDirectRecouvrementModal extends Component {

  state={
    maxLength:45,
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(
      (
        this.props.customerMiseEnDemeureDirectRecouvrementVisible !== prevProps.customerMiseEnDemeureDirectRecouvrementVisible
        || this.props.myCompanies !== prevProps.myCompanies
      )
      && this.props.customerMiseEnDemeureDirectRecouvrementVisible) {
      if (this.props.formRef.current) {
        const {
          companyId,
          miseEnDemeureDrGender,
          miseEnDemeureDrCompanyName,
          miseEnDemeureDrAddress,
          miseEnDemeureDrZip,
          miseEnDemeureDrCity
        } = this.props.formRef.current.getFieldsValue();
        if(companyId){
          const company = _.find(this.props.myCompanies, {id: parseInt(companyId)});
          if(company){
            for(const key in company){
              if(company[key] === 'null') {
                company[key] = null
              }
            }
            this.props.formRef.current.setFieldsValue({
              miseEnDemeureDrGender: miseEnDemeureDrGender ? miseEnDemeureDrGender : company.gender,
              miseEnDemeureDrCompanyName: miseEnDemeureDrCompanyName ? miseEnDemeureDrCompanyName : company.name,
              miseEnDemeureDrAddress: miseEnDemeureDrAddress ? miseEnDemeureDrAddress : company.address,
              miseEnDemeureDrZip: miseEnDemeureDrZip ? miseEnDemeureDrZip : company.zip,
              miseEnDemeureDrCity: miseEnDemeureDrCity ? miseEnDemeureDrCity : company.city
            })
          }
        }
      }
    }
  }

  onFinish = () => {
    this.props.formRef.current.setFieldsValue({submitType: 'mise_en_demeure_direct_recouvrement'});
    this.props.formRef.current.submit();
  };

  handleOnClickRadioGender = (e) => {
    const values = this.props.formRef.current.getFieldsValue();
    const {miseEnDemeureDrGender} = values;
    if(miseEnDemeureDrGender === e.target.value){
      this.props.formRef.current.setFieldsValue({
        miseEnDemeureDrGender: null
      })
    }
  }

  handleAdresseChange = (e) => {
    const inputValue = e.target.value;
    const lines = inputValue.split("\n").map((line) => line.slice(0, 45));    
    const truncatedLines = lines.slice(0, 3);
    const truncatedValue = truncatedLines.join("\n");
    this.props.formRef.current.setFieldsValue({
      miseEnDemeureDrAddress: truncatedValue,
    });
  }

  render(){

    const style = {
      modal: {
       textAlign: 'left',
      },
    };

    const { user, appPrices } = this.props;

    if(!user) return <></>;

    const pricing = appPrices.miseEnDemeureDrPricing;

    return (
      <CustomModal
        title={`Mise en demeure Direct Recouvrement`}
        visible={this.props.customerMiseEnDemeureDirectRecouvrementVisible}
        onCancel={() => this.props.hideModal('customerMiseEnDemeureDirectRecouvrement')}
        footer={null}
        style={style.modal}
        destroyOnClose={true}
      >
        <Row gutter={16} style={{textAlign: 'center', margin: '0', background: colors.primaryGreen}}>
          <Col span={6}><PricingTag title={'FREE'} subtitle={`${pricing.free === 0 ? 'Gratuit' : pricing.free}`} active={'Free' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'STARTER'} subtitle={`${pricing.starter === 0 ? 'Gratuit' : pricing.starter}`} active={'Starter' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PREMIUM'} subtitle={`${pricing.premium === 0 ? 'Gratuit' : pricing.premium}`} active={'Premium' === user.subscriptionType}/></Col>
          <Col span={6}><PricingTag title={'PRIVILÈGE'} subtitle={`${pricing.privilege === 0 ? 'Gratuit' : pricing.privilege}`} active={'Privilege' === user.subscriptionType}/></Col>
          <Col span={24} style={{marginBottom: 20, color: 'white'}}>
            +<br/>
            <strong>Frais administratifs</strong><br/>
            (<NumberFormat
            displayType={'text'}
            value={pricing.fees}
            decimalSeparator={','}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={' '}
            suffix={'€ HT'}
          />)
            <br/>
          </Col>
        </Row>

        <br/>

        <p style={{textAlign: 'center'}}>
        <em>
          Déclenchez l’envoi d’un courrier automatique de notre cabinet Direct Recouvrement
        </em>
        </p>

        <Row gutter={16} style={{margin: 0}}>
          <Col span={4} offset={4} style={{textAlign: 'center', paddingTop: 20}}>
            <FormRadio
              onClick={this.handleOnClickRadioGender}
              name={'miseEnDemeureDrGender'}
              label={null}
              id={null}
              initialValue={'none'}
              options={[{value: 'Mme', label: 'Mme'},{value: 'M.', label: 'M.'}]}
              layout={'horizontal'}
            />
          </Col>
          <Col span={11}>
            <FormInput
              label={`Raison sociale du destinataire`}
              alignLabel='center'
              placeholder={`Raison sociale`}
              name={`miseEnDemeureDrCompanyName`}
              type='text'
              rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false}]}
              disabled={true}
            />
          </Col>
          <Col span={15} offset={4}>
            <FormInput
              label={`Adresse`}
              alignLabel='center'
              placeholder={`Adresse (Maximum 3 lignes, 45 caractères par ligne)`}
              name={`miseEnDemeureDrAddress`}
              maxLength={135}
              type='textArea'
              rules={[{required: true, message: 'Ce champ est obligatoire'},{max : 134 ,  message:`Maximum 135 caractères`, warningOnly: true}]}
              onChange={this.handleAdresseChange}
            />
          </Col>
          <Col span={6} offset={4}>
            <FormInput
              label={`Code postal`}
              alignLabel='center'
              placeholder={`Code postal`}
              name={`miseEnDemeureDrZip`}
              type='text'
              rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false}]}
            />
          </Col>
          <Col span={9}>
            <FormInput
              label={`Ville`}
              alignLabel='center'
              placeholder={`Ville`}
              name={`miseEnDemeureDrCity`}
              type='text'
              maxLength={39}
              rules={[{required: true, message: 'Ce champ est obligatoire', warningOnly: false},{max:38,message:'Maximum 39 caractères'}]}
            />
          </Col>
        </Row>

        <p style={{textAlign: 'center', marginTop: 20}}>
          <em>Montant en principal:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalBalance}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong><br/>
          <em>Pénalités de retard calculées:</em> <strong><NumberFormat
          displayType={'text'}
          value={this.props.globalPenalties}
          decimalSeparator={','}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator={' '}
          suffix={'€'}
        /></strong>
        </p>
        <div style={{textAlign: 'center'}}>
          <Button type='primary' onClick={this.onFinish}>Visualiser</Button>
        </div>
      </CustomModal>
    )
  }
}

export default connect(
  state => ({
    customerMiseEnDemeureDirectRecouvrementVisible: state.modals.customerMiseEnDemeureDirectRecouvrementVisible,
    appPrices: state.app.prices,
    user: state.user.info,
    globalPenalties: state.calculator.globalPenalties,
    globalBalance: state.calculator.globalBalance,
    myCompanies: state.calculator.myCompanies
  }),
  {
    hideModal: modalsActions.hideModal,
  }
)(MiseEnDemeureDirectRecouvrementModal);